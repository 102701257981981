import Vuex from "vuex";

import AdminStore from "@/store/modules/AdminStore";
import QuestionStore from "@/store/modules/QuestionStore";
import { createVuexPersistedState } from "vue-persistedstate";

export default new Vuex.Store({
  plugins: [
    createVuexPersistedState({
      storage: window.sessionStorage,
    }),
  ],

  modules: { AdminStore, QuestionStore },
});
