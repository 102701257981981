import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/MainVue.vue"),
    redirect: { name: "homeVue" },
    children: [
      {
        path: "home",
        name: "homeVue",
        component: () => import("@/views/main/HomeVue.vue"),
      },
      {
        path: "question",
        name: "questionVue",
        component: () => import("@/views/main/QuestionVue.vue"),
      },
      {
        path: "result/:status",
        name: "result",
        component: () => import("@/views/main/ResultVue.vue"),
      },
    ],
  },
  {
    path: "/200",
    name: "home200",
    component: () => import("@/views/MainVue200.vue"),
    redirect: { name: "homeVue200" },
    children: [
      {
        path: "home",
        name: "homeVue200",
        component: () => import("@/views/main/HomeVue200.vue"),
      },
      {
        path: "question",
        name: "questionVue200",
        component: () => import("@/views/main/QuestionVue200.vue"),
      },
      {
        path: "result/:status",
        name: "result200",
        component: () => import("@/views/main/ResultVue200.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
